<template>
	<div class="rule-card">
		<div class="rule-card-head">
			<div>{{title}}{{index+1}}:</div>
			<div>
				<el-button v-if="canDel" type="text" size="mini" class="text-danger mr-2" @click="$emit('remove', index)">删除</el-button>
				<el-checkbox v-model="rule.useOrderBy" size="mini" @change="orderByChanged">排序规则</el-checkbox>
			</div>
		</div>
		<div class="d-flex flex-row">
			<div style="flex-shrink:0;">适用品类范围:</div>
			<treeselect class="feed-tag-tree" v-model="rule.categoryName" :multiple="true" :options="categories"
				placeholder="" search-nested value-consists-of="ALL">
				<label slot="option-label" slot-scope="{ node }" style="transform:translateY(2px);">
					{{ node.label }}
				</label>
				<div slot="value-label" slot-scope="{ node }">{{ node.raw.completeName }}</div>
			</treeselect>
		</div>
		<div>门槛值: <el-switch v-model="rule.threshold" @change="thresholdChanged"></el-switch>
		</div>
		<expression 
			ref="rule-expression"
			v-if="rule.expressions"
			v-for="(expression,index) in rule.expressions"
			:expression="expression"
			:index="index"
			:isLast="index===rule.expressions.length-1"
			:canDel="rule.expressions.length>1"
			@add="addExpression"
			@remove="removeExpression"
			:key="index"></expression>
		<order-by v-if="rule.orderBy" :orderBy="rule.orderBy"></order-by>
	</div>
</template>

<script>
	import OrderBy from './order-by.vue'
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import Expression from './expression.vue'
	export default {
		components: {
			OrderBy,
			Treeselect,
			Expression
		},
		props: {
			title: {
				type: String,
				default: '条件'
			},
			index: {
				type: Number,
				default: 0
			},
			rule: {
				type: Object,
				default: (() => {
					return {
						categoryName: [],
						threshold: false,
						expressions: null,
						orderBy: null,
						useOrderBy: false
					}
				})
			},
			categories: {
				type: Array,
				default: (() => [])
			},
			canDel: {
				type: Boolean,
				default: true
			}
		},
		methods: {
			orderByChanged() {
				const orderBy = {
					direction: 'desc',
					limitCount: 0,
					orderField: 'ORDERS',
					separate: false,
				}
				this.rule.orderBy = this.rule.useOrderBy ? orderBy : null
			},
			thresholdChanged() {
				const expressions = [{
					operator: 'AND',
					symbolLeft: '',
					symbolRight: '',
					valueLeft: '',
					valueRight: '',
					variables: ''
				}]
				this.rule.expressions = this.rule.threshold ? expressions : null
				console.log(this.rule)
			},
			addExpression() {
				this.rule.expressions.push({
					operator: 'AND',
					symbolLeft: '',
					symbolRight: '',
					valueLeft: '',
					valueRight: '',
					variables: ''
				})
			},
			removeExpression(index) {
				this.rule.expressions.splice(index, 1)
			},
			validate() {
				if (this.rule.categoryName.length === 0) {
					return false
				}
				if (this.$refs['rule-expression']) {
					return this.$refs['rule-expression'].reduce((lhs, rhs) => {
						return lhs && rhs.validate()
					}, true)
				}
				return true
			}
		}
	}
</script>

<style lang="scss">
	.rule-card {
		background-color: #f3f3f3;
		border-radius: 5px;
		padding: 15px;
		margin-bottom: 15px;
		position: relative;
		.rule-card-head {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		.feed-tag-tree {
			.vue-treeselect__multi-value-item {
				border-radius: 10px;
				background-color: #f4f4f5;
				border: 1px solid #e9e9eb;
				color: #909399;

				span {
					font-size: 10px;
					line-height: 20px;
				}
			}

			.vue-treeselect__icon.vue-treeselect__value-remove {
				color: #909399;
			}
		}
	}
</style>
