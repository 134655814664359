<template>
</template>

<script>
	import common from '@/common/mixins/common.js'
	import page from '@/common/mixins/page.js'
	export default {
		inject: ['adminLayout'],
		mixins: [common, page],
		props: {
			currentActive: {
				type: String,
				default: ''
			},
			tabs: {
				type: Array,
				default: (() => [])
			}
		},
		data() {
			return {
				q: {},
				queryForm: {},
				form: {},
				page: {
					size: 5,
					sizes: [5, 20, 50, 100],
					current: 1,
					total: 0
				},
				fetchOnCreated: false,
				namespace: ''
			}
		},
		methods: {
			searchEvent() {
				this.q = {
					...this.queryForm
				}
				Object.keys(this.q).forEach(key => {
					if (this.q[key] === '') {
						delete this.q[key]
					}
				})
				this.page.current = 1
				this.getList()
			},
			handleCommand(command) {
				if (this.namespace === '') return
				const ids = this.multipleSelection.map(v => v.id)
				const active = command === 'active' ? 'true' : 'false'
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/activeItems`, {ids, active })
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
			},
			handleList(content) {
				this.tableData = content
			},
			async getListUrl() {
				if (this.namespace === '') return
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q
					},
					trigger
				}
				return this.$store.dispatch(`${this.namespace}/getList`, payload)
			},
			changeActive(item) {
				if (this.namespace === '') return
				const {id, active} = item
				// publish or uppublish item
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/activeItem`, {id, active})
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
			},
			prepareFormData() {
				return {...this.form}
			},
			doCreateOrUpdate() {
				if (this.createApi === '') return
				this.$refs.form.validate(v => {
					if (v) {
						this.adminLayout.showLoading(true)
						const {id} = this.form
						const body = this.prepareFormData()
						if (id) {
							this.$store.dispatch(`${this.namespace}/updateItem`, {id, body})
							.then(res => {
								this.$showSuccess('更新成功')
								this.adminLayout.hideLoading()
								this.visible.add = false
								this.getList()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
							return
						}
						this.$store.dispatch(`${this.namespace}/newItem`, {...body, active: true})
						.then(res => {
							this.$showSuccess('添加成功')
							this.adminLayout.hideLoading()
							this.visible.add = false
							this.getList()
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
					}
				})
			},
			getId(item) {
				return item.id
			},
			fillForm(data) {
				this.form = data
			},
			editRow(item) {
				if (this.namespace === '') return
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/getItem`, this.getId(item))
				.then(data => {
					this.adminLayout.hideLoading()
					this.fillForm(data)
					this.visible.add = true
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
			}
		}
	}
</script>

<style>
</style>
