<template>
	<el-card class="feed-tag">
		<template v-if="hasErr">
			<el-result v-if="hasErr" icon="error" title="错误提示" subTitle="网络错误,请稍后重试">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="__init()">刷新</el-button>
				</template>
			</el-result>
		</template>
		<template v-else>
			<advanced-search-bar>
				<template>
					<form-item-sites ref="form-item-sites" :abbr="true" :multiple="false" :local="true"
						v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;__init()"
						:clearable="false"></form-item-sites>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="editRow(null)">添加配置</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="渠道:">
						<el-select v-model="queryForm.channel" class="w-100" size="mini" @change="queryForm.columnName=''">
							<el-option v-for="item in feedProductChannels" :label="item.value" :value="item.key">
							</el-option>
						</el-select>
					</form-item>
					<form-item label="Tag所在列:">
						<el-select v-model="queryForm.columnName" size="mini">
							<el-option v-for="(item, index) in tagColumnNames" :label="item" :value="item" :key="index"></el-option>
						</el-select>
					</form-item>
					<form-item label="配置规则:">
						<el-select v-model="queryForm.tagRuleType" size="mini" clearable>
							<el-option v-for="item in tagRuleTypes" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="配置状态:">
						<el-select v-model="queryForm.status" size="mini" clearable>
							<el-option v-for="item in states" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
				</template>
			</advanced-search-bar>
			<el-table height="auto" :data="tableData">
				<el-table-column label="站点" prop="website" min-width="120">
				</el-table-column>
				<el-table-column label="渠道" prop="channel" min-width="120">
				</el-table-column>
				<el-table-column label="生效Feeds" prop="feedName" min-width="120">
				</el-table-column>
				<el-table-column label="Tag所在列" prop="columnName" min-width="120">
				</el-table-column>
				<el-table-column label="Tag优先级" prop="priority" min-width="100">
				</el-table-column>
				<el-table-column label="Tag规则" min-width="120">
					<template slot-scope="scope">
						{{scope.row.tagRuleType|label(tagRuleTypes)}}
					</template>
				</el-table-column>
				<el-table-column label="Tag" prop="tagName" min-width="100">
				</el-table-column>
				<el-table-column label="配置状态" min-width="120" align="center">
					<template slot-scope="scope">
						<div :class="feedProductStatus(scope.row.status)">{{scope.row.status|value(states)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="最后更新人" prop="updatedBy.name" min-width="120">
				</el-table-column>
				<el-table-column label="最后更新时间" min-width="200">
					<template slot-scope="scope">
						{{scope.row.updatedAt|formatDate('YYYY-MM-DD HH:mm')}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="300">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status === 'DRAFT'" type="text" size="mini"
							@click="dbConfirm(scope.row, 'TEST')">发布测试</el-button>
						<el-button v-if="scope.row.status === 'TEST'" type="text" size="mini"
							@click="dbConfirm(scope.row, 'ONLINE')">发布正式</el-button>
						<el-button v-if="scope.row.status === 'DRAFT' || scope.row.status === 'TEST'" type="text"
							style="color:#333;" size="mini" @click="editRow(scope.row)">编辑</el-button>
						<el-button type="text" size="mini" @click="showLogs(scope.row)">查看修改日志</el-button>
						<el-button v-if="scope.row.status === 'ONLINE'" class="text-danger" type="text" size="mini"
							@click="dbConfirm(scope.row, 'DISABLED')">关闭</el-button>
						<el-button v-if="scope.row.status === 'CLOSE'" class="text-success" type="text" size="mini"
							@click="dbConfirm(scope.row, 'ENABLED')">开启</el-button>
						<el-button type="text" class="text-danger" size="mini" @click="dbConfirm(scope.row, 'ARCHIVED')">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</template>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" width="1000px">
			<el-form ref="form" :model="form" size="mini" :hide-required-asterisk="true" :rules="rules">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-form-item-sites :abbr="true" v-model="form.website" label-width="50px" prop="website"
							size="mini" @update="websiteChanged"></el-form-item-sites>
					</el-col>
					<el-col :span="6">
						<el-form-item label="渠道:" prop="channel" label-width="50px">
							<el-select v-model="form.channel" class="w-100" @change="channelChanged">
								<el-option v-for="item in feedProductChannels" :label="item.value" :value="item.key"
									:key="item.key"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="生效的Feeds:" label-width="100px">
							<div class="d-flex flex-row align-items-center">
								<el-select class="w-100 feed-names-select" value="" @focus="visible.feedNames = true">
								</el-select>
								<div style="flex-shrink:0;">({{form.feedNames.length}})个</div>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Tag所在列:" label-width="85px">
							<el-select v-model="form.columnName" size="mini" class="w-100">
								<el-option v-for="(item, index) in formTagColumnNames" :label="item" :value="item" :key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Tag优先级:" label-width="85px">
							<el-select v-model="form.priority" class="w-100">
								<el-option v-for="item in 10" :label="item" :value="item" :key="item"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="规则类型:" label-width="75px">
							<el-select v-model="form.tagRuleType" class="w-100" @change="tagRuleTypeChanged">
								<el-option v-for="item in tagRuleTypes" :label="item.value" :value="item.key">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Tag值:" label-width="55px">
							<el-input v-model.trim="form.tagName"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="border-top pt-3" v-if="form.tagRuleType">
				<template v-if="form.tagRuleType === 'SPECIFIED'">
					<el-form ref="form-specified" :model="form.specifiedRule" label-width="90px">
						<el-form-item label="品类">
							<treeselect id="feed-tag-tree" v-model="form.specifiedRule.categories" :multiple="true"
								:options="productCategories" placeholder="" search-nested value-consists-of="ALL">
								<label slot="option-label" slot-scope="{ node }" style="transform:translateY(2px);">
									{{ node.label }}
								</label>
								<div slot="value-label" slot-scope="{ node }">{{ node.raw.completeName }}</div>
							</treeselect>
						</el-form-item>
						<el-form-item label="包含商品ID:">
							<el-input v-model="form.specifiedRule.includeIds" type="textarea"></el-input>
						</el-form-item>
						<el-form-item label="排除商品ID:">
							<el-input v-model="form.specifiedRule.excludeIds" type="textarea"></el-input>
						</el-form-item>
					</el-form>
				</template>
				<template v-else-if="form.tagRuleType === 'CONDITIONAL'">
					<div class="d-flex flex-row align-items-center justify-content-between">
						<div>包含符合以下
							<el-dropdown size="mini" @command="handleCommand">
								<span class="text-primary">
									{{form.conditionalRule.condition|label(conditions)}}
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-for="item in conditions" :command="item.value">{{item.label}}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							条件的商品，打上TAG
						</div>
						<div>
							<el-button type="text" size="mini" @click="addRule">添加条件</el-button>
							<el-button type="text" size="mini" @click="addExcludeRule" class="mr-2">添加排除条件</el-button>
							<el-checkbox v-model="form.conditionalRule.useOrderBy" size="mini" @change="orderByChanged">
								排序规则</el-checkbox>
						</div>
					</div>
					<rule-card ref="rule-card" v-if="form.conditionalRule.rules"
						v-for="(rule, index1) in form.conditionalRule.rules" :categories="formCategories"
						:key="`rule-${index1}`" :index="index1" :rule="rule"
						:canDel="form.conditionalRule.rules.length>1" @remove="removeRule"></rule-card>
					<order-by v-if="form.conditionalRule.orderBy" :orderBy="form.conditionalRule.orderBy"></order-by>
					<rule-card ref="rule-card" v-if="form.conditionalRule.excludeRules" title="排除条件"
						v-for="(rule, index2) in form.conditionalRule.excludeRules" :categories="formCategories"
						:key="`exclude-rule-${index2}`" :index="index2" :rule="rule" @remove="removeExcludeRule">
					</rule-card>
				</template>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add=false" size="mini">取 消</el-button>
				<el-button type="primary" @click="submitForm" size="mini">确 定</el-button>
			</div>
		</el-dialog>
		<el-drawer id="feed-tag-logs" :visible.sync="visible.log" direction="rtl" :size="400"
			:before-close="handleClose">
			<logs :item="selectedItem" :key="logsKey" :allCategories="allCategories"></logs>
		</el-drawer>
		<el-dialog :visible.sync="visible.feedNames">
			<el-select v-model="form.feedNames" class="w-100" multiple>
				<el-option v-for="item in activeFeeds" :label="item" :value="item" :key="item"></el-option>
			</el-select>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="visible.feedNames = false">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import Config from './minixs/index.vue'
	import {
		mapGetters
	} from 'vuex'
	import Logs from '@/components/feeds/tag/logs.vue'
	import {
		uuid
	} from 'vue-uuid'
	import {
		fetchJSONByApis
	} from '@/apis'
	import Treeselect from '@riophae/vue-treeselect'
	import '@riophae/vue-treeselect/dist/vue-treeselect.css'
	import Lodash from 'lodash'
	import RuleCard from '@/components/feeds/tag/rule-card.vue'
	import OrderBy from '@/components/feeds/tag/order-by.vue'
	const DEFAULT_FORM = {
		website: '',
		channel: '',
		feedNames: [],
		columnName: '',
		priority: 1,
		tagName: '',
		tagRuleType: '',
		specifiedRule: {
			categories: [],
			excludeIds: '',
			includeIds: ''
		},
		conditionalRule: {
			condition: 'AND',
			orderBy: null,
			rules: null,
			excludeRules: null,
			useOrderBy: false
		}
	}
	export default {
		components: {
			Logs,
			Treeselect,
			RuleCard,
			OrderBy
		},
		mixins: [Config],
		data() {
			return {
				hasErr: false,
				queryForm: {
					website: '',
					channel: '',
					columnName: '',
					tagRuleType: '',
					status: ''
				},
				visible: {
					add: false,
					detail: false,
					log: false,
					feedNames: false
				},
				tableData: [],
				selectedItem: undefined,
				detailPage: {
					current: 1,
					size: 10
				},
				form: Lodash.cloneDeep(DEFAULT_FORM),
				rules: {
					website: [{
						required: true,
						message: '站点不能为空'
					}],
					channel: [{
						required: true,
						message: '渠道不能为空'
					}],
					columnName: [{
						required: true,
						message: 'Tag所在列不能为空'
					}],
					feedNames: [{
						required: true,
						type: Array,
						message: '生效的Feeds不能为空'
					}],
					priority: [{
						required: true,
						message: '优先级不能为空'
					}],
					tagRuleType: [{
						required: true,
						message: '规则类型不能为空'
					}],
					tagName: [{
						required: true,
						message: 'Tag值不能为空'
					}]
				},
				logsKey: uuid.v4(),
				namespace: 'feeds-content-tag',
				feedProductChannels: [],
				activeFeeds: [],
				allCategories: {}
			}
		},
		filters: {
			label(value, list) {
				try {
					const val = list.find(v => v.value === value)
					return val.label
				} catch (e) {
					const val = list.find(v => v.key === value)
					return val.value
				}
			}
		},
		computed: {
			...mapGetters({
				tagRuleTypes: 'feeds-content-tag/tagRuleTypes',
				states: 'feeds-content-tag/states',
				conditions: 'feeds-content-tag/conditions',
				columnNames: 'feeds-content-tag/columnNames'
			}),
			productCategories() {
				return this.allCategories[this.queryForm.website] || []
			},
			formCategories() {
				return this.allCategories[this.form.website] || []
			},
			tagColumnNames() {
				if (/google/i.test(this.queryForm.channel) === false) {
					return this.columnNames.slice(0,5)
				}
				return this.columnNames
			},
			formTagColumnNames() {
				if (/google/i.test(this.form.channel) === false) {
					return this.columnNames.slice(0,5)
				}
				return this.columnNames
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = Lodash.cloneDeep(DEFAULT_FORM)
				}
			}
		},
		created() {
			this.loadChannels()
		},
		methods: {
			loadChannels() {
				this.$store.dispatch(`${this.namespace}/fetchChannels`)
					.then(res => {
						this.feedProductChannels = Object.keys(res).map(key => {
							return {
								key,
								value: res[key]
							}
						})
					})
					.catch(err => {
						console.log(err)
						setTimeout(() => {
							this.loadChannels()
						}, 2000)
					})
			},
			// Init
			__init() {
				this.websites = this.$refs['form-item-sites'].websites
				this.hasErr = false
				this.adminLayout.showLoading()
				const apis = this.websites.map(({
					key
				}) => this.$store.dispatch(`${this.namespace}/getCategories`, {
					websiteCode: key
				}))
				fetchJSONByApis(...apis)
					.then(results => {
						const convert2Tree = (array) => {
							if (!Array.isArray(array)) return []
							return array.map(v => {
								const children = convert2Tree(v.childs)
								const _v = {
									...v,
									label: v.name
								}
								return children.length > 0 ? {
									..._v,
									children
								} : _v
							})
						}
						results.forEach((result, index) => {
							this.allCategories[this.websites[index].key] = convert2Tree(result)
						})
						this.searchEvent()
					})
					.catch(error => {
						this.hasErr = true
						this.adminLayout.hideLoading()
					})
			},
			editRow(row) {
				if (row) {
					const {rule, tagRuleType,feedName} = row
					var specifiedRule = null
					var conditionalRule = null
					var feedNames = feedName.split(',')
					if (tagRuleType === 'SPECIFIED') {
						try {
							specifiedRule = JSON.parse(rule) || null
							specifiedRule.categoryName = (specifiedRule.categoryName || '').split(',') 
							specifiedRule.excludeIds = (specifiedRule.excludeIds || []).join(',')
							specifiedRule.includeIds = (specifiedRule.includeIds || []).join(',')
						} catch(e) {}
					} else if (tagRuleType === 'CONDITIONAL') {
						try {
							conditionalRule = JSON.parse(rule) || null
							const useOrderBy = !!conditionalRule.orderBy
							conditionalRule.useOrderBy = useOrderBy
							conditionalRule.orderBy = conditionalRule.orderBy || null 
							const rules = conditionalRule.rules || []
							rules.forEach(v => {
								v.useOrderBy = !!v.orderBy
								v.categoryName = v.categoryName.split(',')
								v.expressions = v.expressions || null
								v.orderBy = v.orderBy || null
							})
							const excludeRules = conditionalRule.excludeRules || []
							excludeRules.forEach(v => {
								v.useOrderBy = !!v.orderBy
								v.categoryName = v.categoryName.split(',')
								v.expressions = v.expressions || null
								v.orderBy = v.orderBy || null
							})
							conditionalRule = {
								...conditionalRule,
								rules,
								excludeRules
							}
						} catch (e) {}
					}
					this.form = {
						...Lodash.cloneDeep(DEFAULT_FORM),
						...row,
						specifiedRule,
						conditionalRule,
						feedNames
					}
				} else {
					this.form.website = this.queryForm.website
				}
				this.selectedItem = row
				this.visible.add = true
			},
			submitForm() {
				this.$refs.form.validate((v) => {
					if (v) {
						const form = Lodash.cloneDeep(this.form)
						form.feedName = form.feedNames.join(',')
						const flatMapCategories = (array) => {
							return array.reduce((lhs, rhs) => {
								const res = lhs.concat([rhs])
								if (Array.isArray(rhs.children)) {
									return res.concat(flatMapCategories(rhs.children))
								}
								return res
							}, [])
						}
						const flatMapedCategories = flatMapCategories(this.formCategories)
						if (form.tagRuleType === 'SPECIFIED') {
							form.specifiedRule.excludeIds = form.specifiedRule.excludeIds.match(/[\w-]+/g) || []
							form.specifiedRule.includeIds = form.specifiedRule.includeIds.match(/[\w-]+/g) || []
							form.specifiedRule.categories = form.specifiedRule.categories.filter(v => v !== '')
							if (form.specifiedRule.includeIds.length === 0 && form.specifiedRule.categories.length === 0) {
								return this.$showErrMsg('需指定品类或商品')
							}
							form.specifiedRule.categoryNames = form.specifiedRule.categories.map(k => flatMapedCategories.find(j => j.id === k).completeName)
						} else if (form.tagRuleType === 'CONDITIONAL') {
							const res = this.$refs['rule-card'].reduce((lhs, rhs) => {
								return lhs && rhs.validate()
							}, true)
							if (!res) {
								return this.$showErrMsg('条件配置不正确，请修正后再提交')
							}
							try {
								form.conditionalRule.rules.forEach(rule => {
									rule.categoryNames = rule.categoryName.map(k => flatMapedCategories.find(j => j.id === k).completeName)
									rule.categoryName = rule.categoryName.join(',')
								})
							} catch (e) {}
							try {
								form.conditionalRule.excludeRules.forEach(rule => {
									rule.categoryNames = rule.categoryName.map(k => flatMapedCategories.find(j => j.id === k).completeName)
									rule.categoryName = rule.categoryName.join(',')
								})
							} catch (e) {}
						}
						const {
							id
						} = this.form
						this.adminLayout.showLoading(true)
						this.$store.dispatch(`${this.namespace}/saveTag`, form)
							.then(res => {
								this.adminLayout.hideLoading()
								this.visible.add = false
								this.$showSuccess(id === undefined ? '添加成功' : '更新成功')
								if (id) {
									const item = this.tableData.find(v => v.id === id)
									Object.assign(item, res)
								} else {
									this.searchEvent()
								}
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
					}
				})
			},
			doAction(action, id) {
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/manageTag`, {
						action,
						id
					})
					.then(res => {
						this.adminLayout.hideLoading()
						this.getList()
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
			},
			dbConfirm(row, action) {
				switch (action) {
					case 'TEST':
						this.$confirm('是否发布到测试?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.doAction(action, row.id)
						})
					break
					case 'ARCHIVED':
						this.$confirm('是否删除该条配置?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.adminLayout.showLoading()
							this.$store.dispatch(`${this.namespace}/delTag`, row.id)
								.then(res => {
									this.adminLayout.hideLoading()
									this.getList()
								})
								.catch(err => {
									this.$showErrMsg(err)
									this.adminLayout.hideLoading()
								})
						})
						break
					case 'ENABLED':
					case 'DISABLED':
						this.$confirm(action === 'ENABLED' ? '是否开启该条配置?' : '是否关闭该条配置?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.doAction(action, row.id)
						})
						break
					case 'ONLINE':
						this.adminLayout.showLoading()
						const params = {
							website: row.website,
							channel: row.channel,
							status: ['ONLINE', 'CLOSE']
						}
						this.$store.dispatch(`${this.namespace}/getList`, {
								params
							})
							.then(res => {
								console.log(res)
								this.adminLayout.hideLoading()
								this.$confirm(res.totalCount === 0 ? '是否发布到正式？' : '已有相同条件配置，请确认是否更新原有配置？', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									this.doAction(action, row.id)
								})
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						break
				}
			},
			showLogs(row) {
				this.selectedItem = row
				this.logsKey = uuid.v4()
				this.$nextTick(() => {
					this.visible.log = true
				})
			},
			websiteChanged() {
				this.activeFeeds = []
				this.form.feedNames = []
				this.form.specifiedRule.categories = []
				try {
					this.form.conditionalRule.rules.forEach(rule => {
						rule.categoryName = []
					})
				} catch (e) {}
				try {
					this.form.conditionalRule.excludeRules.forEach(rule => {
						rule.categoryName = []
					})
				} catch (e) {}
				this.loadActiveFeeds()
			},
			channelChanged() {
				this.activeFeeds = []
				this.form.feedNames = []
				this.form.columnName = ''
				this.loadActiveFeeds()
			},
			loadActiveFeeds() {
				if (this.form.website && this.form.channel) {
					const payload = {
						website: this.form.website,
						channel: this.form.channel
					}
					this.$store.dispatch(`${this.namespace}/fetchActiveFeeds`, payload)
						.then(res => {
							this.activeFeeds = res
						})
				}
			},
			feedProductStatus(key) {
				const item = this.states.find(v => v.key === key)
				return item && `text-${item.status}` || ''
			},
			handleCommand(command) {
				this.form.conditionalRule.condition = command
			},
			tagRuleTypeChanged() {
				if (this.form.tagRuleType === 'SPECIFIED') {
					this.form.specifiedRule = {
						categories: [],
						excludeIds: '',
						includeIds: ''
					}
					this.form.conditionalRule = null
				} else if (this.form.tagRuleType === 'CONDITIONAL') {
					this.form.specifiedRule = null
					this.form.conditionalRule = {
						condition: 'AND',
						orderBy: null,
						rules: [{
							categoryName: [],
							expressions: null,
							orderBy: null,
							threshold: false
						}],
						excludeRules: null
					}
				}
			},
			orderByChanged() {
				const orderBy = {
					direction: 'desc',
					limitCount: 0,
					orderField: 'ORDERS',
					separate: false
				}
				this.form.conditionalRule.orderBy = this.form.conditionalRule.useOrderBy ? orderBy : null
			},
			addRule() {
				const rule = {
					categoryName: [],
					expressions: null,
					orderBy: null,
					threshold: false
				}
				this.form.conditionalRule.rules.push(rule)
			},
			addExcludeRule() {
				const rule = {
					categoryName: [],
					expressions: null,
					orderBy: null,
					threshold: false
				}
				if (this.form.conditionalRule.excludeRules) {
					this.form.conditionalRule.excludeRules.push(rule)
				} else {
					this.form.conditionalRule.excludeRules = [rule]
				}
			},
			removeRule(index) {
				this.form.conditionalRule.rules.splice(index, 1)
			},
			removeExcludeRule(index) {
				this.form.conditionalRule.excludeRules.splice(index, 1)
			}
		}
	}
</script>

<style lang="scss">
	#feed-tag-tree {
		.vue-treeselect__multi-value-item {
			border-radius: 10px;
			background-color: #f4f4f5;
			border: 1px solid #e9e9eb;
			color: #909399;

			span {
				font-size: 10px;
				line-height: 20px;
			}
		}

		.vue-treeselect__icon.vue-treeselect__value-remove {
			color: #909399;
		}
	}

	#feed-tag-logs {
		.el-drawer__header {
			padding: 0;
			margin-bottom: 0;

			span {
				display: none;
			}
		}
	}

	.feed-names-select {
		.el-input__suffix {
			display: none;
		}
	}
</style>
