<template>
	<div
		class="feed-logs"
		v-infinite-scroll="loadData"
		infinite-scroll-disabled="disabled">
		<div v-for="(log, index) in logs" class="feed-log">
			<div>{{log.createdAt|formatDate('YYYY-MM-DD HH:mm')}} {{log.createdBy.name}} {{log.actionDisplay}}</div>
			<table width="100%">
				<tr>
					<td width="25%" style="vertical-align: baseline;">站点:</td>
					<td width="75%">{{log.body.website}}</td>
				</tr>
				<tr>
					<td style="vertical-align: baseline;">渠道:</td>
					<td>{{log.body.channel}}</td>
				</tr>
				<tr>
					<td style="vertical-align: baseline;">生效feeds:</td>
					<td style="word-break:break-word;">{{log.body.feedName}}</td>
				</tr>
				<tr>
					<td style="vertical-align: baseline;">Tag所在列:</td>
					<td>{{log.body.columnName}}</td>
				</tr>
				<tr>
					<td>Tag优先级:</td>
					<td>{{log.body.priority}}</td>
				</tr>
			</table>
			<div class="border-top pt-1 mt-2">规则内容:</div>
			<div>
				<table width="100%">
					<tr v-for="rule in log.rules_">
						<td width="25%" style="vertical-align: baseline;">{{rule.name}}</td>
						<td width="75%">
							<div v-for="item,index1 in rule.content" :key="index1">{{item}}</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="h-100" :class="{'d-none': !loading}" v-loading="loading" element-loading-text="加载中..."></div>
	</div>
</template>

<script>
	export default {
		props: {
			item: {
				type: Object,
				default: (() => {})
			},
			allCategories: {
				type: Object,
				default: (() => {})
			}
		},
		data() {
			return {
				loading: false,
				logs: [],
				namespace: 'feeds-content-tag'
			}
		},
		mounted() {
			this.loadData()
		},
		methods: {
			loadData() {
				if (this.loading) return
				this.loading = true
				const { id } = this.item
				this.$store.dispatch(`${this.namespace}/getLogs`, id)
					.then((logs) => {
						this.logs = logs.map(v => {
							const {tagRuleType} = v.body
							const rule = JSON.parse(v.body.rule) || null
							const rules_ = []
							const websiteCategories = this.allCategories[v.body.website]
							const flatMapCategories = (array) => {
								return array.reduce((lhs, rhs) => {
									const res = lhs.concat([rhs])
									if (Array.isArray(rhs.children)) {
										return res.concat(flatMapCategories(rhs.children))
									}
									return res
								}, [])
							}
							const flatMapedCategories = flatMapCategories(websiteCategories)
							if (tagRuleType === 'SPECIFIED') {
								const {categories, includeIds, excludeIds} = rule
								const content = categories.map(id => flatMapedCategories.find(v => v.id === id)).filter(v => v !== null).map(v => v.completeName)
								rules_.push({
									name: '规则类型',
									content: ['指定品类/商品']
								})
								rules_.push({
									name: '品类',
									content
								})
								rules_.push({
									name: '包含商品id',
									content: includeIds
								})
								rules_.push({
									name: '排除商品id',
									content: excludeIds
								})
							} else if (tagRuleType === 'CONDITIONAL') {
								const {condition, rules, orderBy, excludeRules} = rule
								rules_.push({
									name: '规则类型',
									content: ['条件规则']
								})
								rules_.push({
									name: '包含条件类型',
									content: [condition === 'AND' ? '所有': '任一']
								})
								if (rules) {
									rules.forEach((v, i) => {
										const {categoryName, orderBy, threshold, expressions} = v
										const content = []
										const categoryNames = categoryName.split(',').map(id => flatMapedCategories.find(v => v.id === id)).filter(v => v !== null).map(v => v.completeName)
										content.push('品类:' +  categoryNames.join(','))
										if (expressions) {
											const res = expressions.map(({
												operator,
												symbolLeft,
												symbolRight,
												valueLeft,
												valueRight,
												variables
											}, j) => {
												var text = ''
												if (j > 0) {
													text = operator
												}
												if (symbolLeft && valueLeft) {
													text += ` ${valueLeft} ${symbolLeft}`
												}
												text += ` ${variables}`
												if (symbolRight && valueRight) {
													text += ` ${symbolRight} ${valueRight} `
												}
												return text
											}).join(' ')
											content.push('门槛值:' + res)
										}
										if (orderBy) {
											const {direction, orderField, limitCount, separate} = orderBy
											const direct = direction === 'asc' ? '升序': '降序'
											var str = `排序: 满足条件后按${orderField}${direct}排列并取前${limitCount}个`
											str += separate ? ',按品类细分数据' : ''
											content.push(str)
										}
										rules_.push({
											name: `条件${i+1}`,
											content
										})
									})
								}
								if (orderBy) {
									const {direction, orderField, limitCount, separate} = orderBy
									const direct = direction === 'asc' ? '升序': '降序'
									const content = [`满足条件后按${orderField}${direct}排列并取前${limitCount}个`]
									if (separate) {
										content.push('按品类细分数据')
									}
									rules_.push({
										name: '排序规则',
										content
									})
								}
								if (excludeRules) {
									excludeRules.forEach((v, i) => {
										const {categoryName, orderBy, threshold, expressions} = v
										const content = []
										const categoryNames = categoryName.split(',').map(id => flatMapedCategories.find(v => v.id === id)).filter(v => v !== null).map(v => v.completeName)
										content.push('品类:' +  categoryNames.join(','))
										if (expressions) {
											const res = expressions.map(({
												operator,
												symbolLeft,
												symbolRight,
												valueLeft,
												valueRight,
												variables
											}, j) => {
												var text = ''
												if (j > 0) {
													text = operator
												}
												if (symbolLeft && valueLeft) {
													text += ` ${valueLeft} ${symbolLeft}`
												}
												text += ` ${variables}`
												if (symbolRight && valueRight) {
													text += ` ${symbolRight} ${valueRight} `
												}
												console.log(text)
												return text
											}).join(' ')
											content.push('门槛值:' + res)
										}
										if (orderBy) {
											const {direction, orderField, limitCount, separate} = orderBy
											const direct = direction === 'asc' ? '升序': '降序'
											var str = `排序: 满足条件后按${orderField}${direct}排列并取前${limitCount}个`
											str += separate ? ',按品类细分数据' : ''
											content.push(str)
										}
										rules_.push({
											name: `排除条件${i+1}`,
											content
										})
									})
								}
							}
							return {
								...v,
								rules_
							}
						})
						this.loading = false
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.loading = false
					})
			}
		}
	}
</script>

<style lang="scss">
	.feed-logs {
		padding: 10px;
		overflow-y: auto;
		height: 100%;

		.feed-log {
			margin-bottom: 20px;
			padding: 15px 10px;
			font-size: 14px;
			border: 1px solid #ddd;
			border-radius: 3px;
		}
	}
</style>
