<template>
	<div>
		满足以上条件后按
		<el-dropdown size="mini" @command="updateOrderField">
			<span class="text-primary orderby-dropdown">
				{{orderBy.orderField}}
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item v-for="item in orderFields" :command="item">{{item}}</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
		<el-dropdown size="mini" @command="updateDirection">
			<span class="text-primary orderby-dropdown">
				{{orderBy.direction|label(sorts)}}
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item v-for="item in sorts" :command="item.value">{{item.label}}</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
		排列并取前 <el-input-number v-model="orderBy.limitCount" :controls="false" size="mini" style="width:50px;"></el-input-number>
		个
		<el-checkbox class="d-block" v-model="orderBy.separate" size="mini">按品类细分数据</el-checkbox>
	</div>
</template>

<script>
	export default {
		props: {
			orderBy: {
				type: Object,
				default: (() => {
					return {
						direction: 'desc',
						limitCount: 0,
						orderField: 'ORDERS',    
						separate: false
					}
				})
			}
		},
		data() {
			return {
				orderFields: ['ORDERS', 'CR'],
				sorts: [{
					value: 'desc',
					label: '降序'
				}, {
					value: 'asc',
					label: '升序'
				}]
			}
		},
		filters: {
			label(value, list) {
				return list.find(v => v.value === value).label
			}
		},
		methods: {
			updateOrderField(command) {
				this.orderBy.orderField = command
			},
			updateDirection(command) {
				this.orderBy.direction = command
			}
		}
	}
</script>

<style lang="scss">
	.orderby-dropdown {
		padding: 5px;
		border: 1px solid #007bff;
		border-radius: 4px;
		margin: 0 5px;
	}
</style>