<template>
	<div class="d-flex flex-row align-items-center p-2">
		<el-select v-model="expression.operator" size="mini" style="width:80px;" :style="{'opacity': index === 0 ? 0:1}" :disabled="index === 0">
			<el-option label="AND" value="AND"></el-option>
			<el-option label="OR" value="OR"></el-option>
		</el-select>
		<el-input class="text-center mx-1" style="width:60px;" v-model.trim="expression.valueLeft" size="mini"></el-input>
		<el-select v-model="expression.symbolLeft" style="width:60px;" size="mini" placeholder=" ">
			<el-option label="" value=""></el-option>
			<el-option label="<" value="<"></el-option>
			<el-option label="<=" value="<="></el-option>
		</el-select>
		<el-select v-model="expression.variables" style="width:150px;" :clearable="false" size="mini" class="mx-1">
			<el-option v-for="item in variables" :label="item" :value="item" :key="item"></el-option>
		</el-select>
		<el-select v-model="expression.symbolRight" style="width:60px;" size="mini" placeholder=" ">
			<el-option label="" value=""></el-option>
			<el-option label="<" value="<"></el-option>
			<el-option label="<=" value="<="></el-option>
		</el-select>
		<el-input class="text-center mx-1" style="width:60px;" v-model.trim="expression.valueRight" size="mini"></el-input>
		<el-button v-if="canDel" style="border:1px dashed;width:60px;" size="mini" @click="$emit('remove', index)">-删除</el-button>
		<el-button v-if="isLast" style="border:1px dashed;width:60px;" size="mini" @click="$emit('add')">+添加</el-button>
	</div>
</template>

<script>
	export default {
		props: {
			index: {
				type: Number,
				default: 0
			},
			isLast: {
				type: Boolean,
				default: true
			},
			canDel: {
				type: Boolean,
				default: false
			},
			expression: {
				type: Object,
				default: (() => {
					return {
						operator: 'AND',
						symbolLeft: '',
						symbolRight: '',
						valueLeft: '',
						valueRight: '',
						variables: ''
					}
				})
			}
		},
		data() {
			return {
				variables: ['IMPRESSIONS', 'CLICK', 'CTR', 'SALES', 'USER', 'CR']
			}
		},
		methods: {
			validate() {
				if (!this.expression.variables) {
					return false
				}
				if ((this.expression.symbolLeft && this.expression.valueLeft) || (this.expression.symbolRight && this.expression.valueRight)) {
					return true
				}
				return false
			}
		}
	}
</script>

<style lang="scss">
</style>